import React, { useState, useEffect } from "react";

import { Button } from "components/anti/buttons/buttons"
import Layout from "components/layout"
import PinInput from "react-pin-input";
import { parseCookies, destroyCookie, setCookie } from 'nookies'
import { useMutation, useLazyQuery } from "@apollo/client"
import { navigate } from "gatsby"
import Seo from "components/seo"

import { createPINQuery } from "components/utils/streamworx"


const RegisterCreatePin = () => {
    const cookies = parseCookies()

    const [createPin1, setcreatePin1] = useState()
    const [createPin2, setcreatePin2] = useState()
    const [disabledBtn1, setDisabledBtn1] = useState(true)
    const [disabledBtn2, setDisabledBtn2] = useState(true)
    const [verifyPin, setVerifyPin] = useState(false)
    const [errMsg, setErrMsg] = useState("")

    const [createPIN] = useMutation(createPINQuery, { context: { clientName: 'streamworx' } })


    const onChange1 = value => {
        setcreatePin1(value)
        if (value.length >= 6) {
            setDisabledBtn1(false)
        } else {
            setDisabledBtn1(true)
        }
    };

    const onChange2 = value => {
        setcreatePin2(value)
        if (value.length >= 6) {
            setDisabledBtn2(false)
        } else {
            setDisabledBtn2(true)
        }
    };

    useEffect(() => {
        let list = document.querySelectorAll('[placeholder]');
        for (let i = 0; i < list.length; i++) {
            if (list[i].placeholder) {
                list[i].classList.add('filled')
            } else {
                list[i].classList.remove('filled')
            }
        }

    }, [createPin1, createPin2])

    const handleCreatePin = () => {
        setVerifyPin(true)
    }
    const handleVerifyPin = () => {
        if (createPin1 === createPin2) {
            const phoneNumber = cookies.phoneNumber
            const loginDirect = cookies.loginDirect

            const value = {
                phoneNumber,
                pin: createPin1
            }
            createPIN({
                variables: { ...value }
            }).then(res => {
                console.log("berhasil", res)
                const status = res.data.createPIN.status
                const token = res.data.createPIN.token

                if (status === "SUCCESS") {
                    setCookie(null, 'authToken', token, {
                        maxAge: 12 * 60 * 60,
                        path: '/',
                    })
                    destroyCookie({}, 'phoneNumber')
                    if (loginDirect) {
                        navigate(loginDirect)
                        destroyCookie({}, 'loginDirect')
                    } else {
                        navigate("/")

                    }
                } else {
                    setErrMsg("Mohon maaf, terjadi kesalahan pada sistem.")
                }
            }).catch(res => {
                console.log("err", res)
                setErrMsg("Mohon maaf, terjadi kesalahan pada sistem.")
            })

        } else {
            setErrMsg("PIN tidak sama, pastikan PIN yang anda masukkan sesuai dengan PIN sebelumnya")
            console.log("salah")
        }
    }

    useEffect(() => {
        const phoneNumber = cookies.phoneNumber
        if (!phoneNumber) {
            // navigate("/login")
            window.location.replace("/")
        }
    }, [])

    return (
        <Layout>
            <Seo title="Register" />
            <section className="py-main sc-login-pin">
                <div className="container mw-sm">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/login"}
                    >
                        Kembali
                    </Button>
                    <div className={`pin-field ${!verifyPin ? "d-block" : "d-none"}`}>
                        <h2>Buat PIN</h2>
                        <p>Untuk keamanan kamu, mohon membuat kode PIN berupa 6 digit angka</p>
                        <PinInput
                            length={6}
                            focus
                            // disabled
                            secret
                            type="numeric"
                            onChange={onChange1}
                        />
                        <Button variant="primary" disabled={disabledBtn1} onClick={handleCreatePin} className="btn-block">
                            Lanjut
                        </Button>
                    </div>
                    <div className={`pin-field pin-2 ${verifyPin ? "d-block" : "d-none"}`}>
                        <h2>Ulangi PIN</h2>
                        <p>Mohon ulangi 6 digit kode PIN yang telah kamu buat</p>
                        <PinInput
                            length={6}
                            focus
                            // disabled
                            secret
                            type="numeric"
                            onChange={onChange2}
                        />
                        <Button variant="primary" disabled={disabledBtn2} onClick={handleVerifyPin} className="btn-block">
                            Lanjut
                        </Button>
                        <div className="invalid-feedback">{errMsg}</div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default RegisterCreatePin